import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useLayoutEffect,
} from 'react';
import {
  BUTTON_SIZE,
  generateData,
  GeneratedElement,
  SCENE_SIZE,
  Sequence,
  STAGES,
  TIME_DECREMENT,
  TIMER_START,
  TOTAL_SEQUENCES,
  transformSequencesToSteps,
} from '../utils/generator';
import { Button, ButtonSize } from 'shared/button';
import { Gradient } from 'shared/gradient';
import { Carousel } from 'shared/carousel';
import { CentredWrapper, H100 } from 'shared/layout';
import { colors } from 'app/colors';
import { GradientTimer } from 'shared/gradientTimer';
import { Step, StepDirection, Steps } from 'shared/steps';
import {
  BubbleArea,
  ExitWrapper,
  GameButton,
  GameWrapper,
  InfoWrapper,
  RobotWrapper,
  Scene3dContainerGamePage,
  StatsWrapper,
  Streak,
} from '../Game.styled';
import { Energy } from 'feature/game/energy';
import { useAppDispatch, useAppSelector } from 'app/store/rootStore';
import {
  selectBalance,
  selectEnergy,
  updateBalance,
  updateEnergy,
} from 'entities/user';
import { Text } from 'shared/typography';
import { use3dModelContext } from '../../3d';
import { StyledText } from 'widget/fakeMint/FakeMint.styled';
import { Counter } from 'shared/counter';
import { setIsShowMenu, setShowStars } from 'entities/settings';
import { CloseIcon } from 'shared/icon';
import { StreakAnim } from './Streak';
import { useModal } from 'shared/modal';
import { waiter } from 'shared/utils';
import { OutOfEnergy } from './OutOfEnergy';
import { CharacterStats } from 'widget/characterStats';

const MemoizedGameButton = React.memo(GameButton);
const MemoizedGradientTimer = React.memo(GradientTimer);
const MemoizedSteps = React.memo(Steps);

const defaultSeqences = {
  primary: [],
  fake: [],
  final: [],
};

export const MainGame: React.FC = () => {
  const energy = useAppSelector(selectEnergy);
  const walletBalance = useAppSelector(selectBalance);
  const dispatch = useAppDispatch();

  const { setIsOpen, setModalProps } = useModal();

  const { mount3dScene, unmount3dScene, movementControllerOfModel } =
    use3dModelContext();

  const [gameStarted, setGameStarted] = useState(false);
  const [clicked, setClicked] = useState<any>([]);
  const [stage, setStage] = useState<number>(1);

  const [correctSequencesCount, setCorrectSequencesCount] = useState(0);

  const [sequences, setSequences] = useState<Sequence>(defaultSeqences);
  const [currentSequenceIndex, setCurrentSequenceIndex] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);

  const [sequenceCompleted, setSequenceCompleted] = useState(false);
  const [sequenceFailed, setSequenceFailed] = useState(false);

  const [streak, setStreak] = useState(0);
  const [multiplier, setMultiplier] = useState(1);

  const [timer, setTimer] = useState(STAGES[1].gameTime);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const scene3dContainerGamePage = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const carouselRef = useRef<any>(null);

  const [randomPositions, setRandomPositions] = useState<
    { top: number; left: number; size: ButtonSize }[]
  >([]);

  const handleOutOfEnergy = () => {
    setIsOpen(false);
    if (gameStarted) {
      closeGame();
    }
  };
  const generate = useCallback(() => {
    if (energy - 1 < 3) {
      setModalProps({
        onRequestClose: handleOutOfEnergy,
        children: <OutOfEnergy onNext={handleOutOfEnergy} />,
      });
      setIsOpen(true);
      return;
    }
    const generatedSequences: Sequence = sequences;
    for (let i = 0; i < TOTAL_SEQUENCES; i++) {
      const { primaryArray, finalArray, fakeElements } = generateData(
        STAGES[stage].minPrimary,
        STAGES[stage].maxPrimary,
        STAGES[stage].minFake,
        STAGES[stage].maxFake
      );
      console.log('finalArray:', finalArray);
      console.log('primaryArray:', primaryArray);
      generatedSequences.final.push(finalArray);
      generatedSequences.primary.push(primaryArray);
      generatedSequences.fake.push(fakeElements);
    }
    console.log('generatedSequences:', generatedSequences);

    setTimer(STAGES[stage].gameTime);
    setSequences(generatedSequences);
    setGameStarted(true);
  }, [
    sequences.final,
    sequences.primary,
    currentSequenceIndex,
    movementControllerOfModel,
    stage,
    energy,
    defaultSeqences,
    gameStarted,
  ]);

  useEffect(() => {
    dispatch(setShowStars(false));
    if (
      correctSequencesCount > 0 &&
      correctSequencesCount % 10 === 0 &&
      correctSequencesCount < 100
    ) {
      setStage(correctSequencesCount / 10 + 1);
      dispatch(setShowStars(true));
    }
  }, [correctSequencesCount]);

  useEffect(() => {
    if (streak > 0 && streak % 10 === 0 && streak / 10 < 10) {
      setMultiplier(streak + 1 < 11 ? streak + 1 : multiplier);
    } else if (streak === 0) {
      setMultiplier(1);
    }
  }, [streak]);

  const handleClick = useCallback(
    async (step: number) => {
      console.log('click');
      if (!gameStarted || sequenceCompleted || sequenceFailed) return;
      const currentSequence = sequences.primary[currentSequenceIndex];
      setClicked((prev: boolean[]) => [...prev, true]);
      dispatch(updateEnergy(energy - 1));

      if (step === currentSequence[currentStep].step) {
        const isLastStep = currentStep + 1 === currentSequence.length;
        if (isLastStep) {
          completeSequence();
        } else {
          setCurrentStep((prevStep) => prevStep + 1);
        }
      } else {
        failSequence();
      }
    },
    [
      gameStarted,
      sequenceCompleted,
      sequenceFailed,
      currentSequenceIndex,
      currentStep,
      energy,
      dispatch,
    ]
  );

  useLayoutEffect(() => {
    if (gameStarted && sequences.final[currentSequenceIndex]) {
      generateRandomPositions(sequences.final[currentSequenceIndex]);
    }
  }, [currentSequenceIndex, gameStarted, sequences.primary]);

  const completeSequence = useCallback(() => {
    movementControllerOfModel?.changeSintPosByRandom();
    setSequenceCompleted(true);
    console.log('comp', currentSequenceIndex + 1, sequences.primary);
    setTimeout(() => {
      const nextSequenceIndex = currentSequenceIndex + 1;

      if (nextSequenceIndex === sequences.primary.length) {
        generate();

        setCurrentSequenceIndex(nextSequenceIndex);
        setCurrentStep(0);

        setSequenceCompleted(false);

        // Обновляем количество правильных последовательностей
        setCorrectSequencesCount((prev) => prev + 1);

        setStreak((prevStreak) => prevStreak + 1);
        setMultiplier((prevStreak) =>
          prevStreak + 1 < 11 ? prevStreak + 1 : prevStreak
        );
        dispatch(
          updateBalance(walletBalance + STAGES[stage].reward * multiplier)
        );
        // updateBalance()
        // const newTimer = TIMER_START - TIME_DECREMENT * (streak + 1);
        // setTimer(newTimer > 0 ? newTimer : 0);

        setClicked([]);
        carouselRef.current.goToSlide(nextSequenceIndex);

        // resetTimer(newTimer > 0 ? newTimer : 0);
      }
    });
  }, [
    currentSequenceIndex,
    currentStep,
    walletBalance,
    sequences.primary,
    dispatch,
    generate,
    multiplier,
    streak,
  ]);

  const failSequence = useCallback(() => {
    setSequenceFailed(true);
    clearInterval(timerRef.current!);

    setTimeout(() => {
      const nextSequenceIndex = currentSequenceIndex + 1;
      if (nextSequenceIndex === sequences.primary.length) {
        generate();

        setCurrentSequenceIndex(nextSequenceIndex);
        setCurrentStep(0);
        setSequenceFailed(false);
        setStreak(0);
        setMultiplier(1);
        setClicked([]);
        carouselRef.current.goToSlide(nextSequenceIndex);
        resetTimer(STAGES[stage].gameTime);
      }
    });
  }, [currentSequenceIndex, sequences.primary, generate, gameStarted]);

  useEffect(() => {
    if (timer < TIME_DECREMENT) {
      failSequence();
    }
  }, [timer]);

  useEffect(() => {
    if (gameStarted) {
      movementControllerOfModel?.moveSintToStartGame();
    }
  }, [gameStarted]);

  useEffect(() => {
    if (gameStarted) {
      dispatch(setIsShowMenu(false));
    }

    return () => {
      dispatch(setIsShowMenu(true));
    };
  }, [gameStarted]);

  const resetTimer = (time: number) => {
    clearInterval(timerRef.current!);
    setTimer(time);

    timerRef.current = setInterval(() => {
      setTimer((prev) => {
        return prev - 1000;
      });
    }, 1000);
  };

  useEffect(() => {
    if (gameStarted) {
      resetTimer(STAGES[stage].gameTime);
    }

    return () => {
      clearInterval(timerRef.current!);
    };
  }, [gameStarted]);

  const closeGame = () => {
    console.log('game end');
    movementControllerOfModel?.moveSintToGamePageWithAnim();
    setSequences(() => ({ fake: [], primary: [], final: [] }));
    setClicked([]);
    setCurrentSequenceIndex(0);
    setGameStarted(false);
    setStreak(0);
    setMultiplier(1);
    setStage(1);
    setCorrectSequencesCount(0);
    setTimer(STAGES[1].gameTime);
  };

  useEffect(() => {
    console.log('Updated sequences:', sequences);
  }, [sequences.primary]);
  const generateRandomPositions = useCallback(
    (elements: GeneratedElement[]) => {
      if (!containerRef.current) return;

      const containerWidth = containerRef.current.offsetWidth;
      const containerHeight = containerRef.current.offsetHeight;

      const sizes = STAGES[stage].bubbleSize;
      const positions: { top: number; left: number; size: ButtonSize }[] = [];

      while (positions.length < elements.length) {
        const top = Math.random() * (containerHeight - BUTTON_SIZE);
        const left = Math.random() * (containerWidth - BUTTON_SIZE);
        const isOverlapping = positions.some(
          (pos) =>
            Math.abs(pos.top - top) < BUTTON_SIZE &&
            Math.abs(pos.left - left) < BUTTON_SIZE
        );

        if (!isOverlapping) {
          const size: any = sizes[Math.floor(Math.random() * sizes.length)];
          positions.push({ top, left, size });
        }
      }
      setRandomPositions(positions);
    },
    [currentSequenceIndex]
  );
  const currentBubble = sequences.final[currentSequenceIndex] || [];

  useEffect(() => {
    if (scene3dContainerGamePage && scene3dContainerGamePage.current) {
      console.log('SCENE-MOUNT', scene3dContainerGamePage.current.id);
      mount3dScene(scene3dContainerGamePage.current.id);
    }
  }, [scene3dContainerGamePage]);

  useEffect(() => {
    if (
      movementControllerOfModel &&
      scene3dContainerGamePage &&
      scene3dContainerGamePage.current
    ) {
      movementControllerOfModel.moveSintToGamePage();
    }
  }, [movementControllerOfModel]);

  useLayoutEffect(() => {
    return () => {
      console.log('SCENE-UNMOUNT');
      unmount3dScene();
    };
  }, []);

  return (
    <>
      {!gameStarted && <CharacterStats />}

      <GameWrapper id={'GAME-WRAPPER'}>
        <RobotWrapper>
          <Scene3dContainerGamePage
            id={'3d-scene-game-page'}
            ref={scene3dContainerGamePage}
          />
        </RobotWrapper>
        {!gameStarted ? (
          <CentredWrapper>
            <div
              style={{ width: SCENE_SIZE.width, height: SCENE_SIZE.height }}
            ></div>

            <StyledText>
              beta version of
              <br /> the game
            </StyledText>
            <Button
              onClick={generate}
              decoration='basic'
              size='l'
              style={{ zIndex: 10 }}
              borderColor={colors.secondaryColor}
            >
              <Gradient color={colors.secondaryColor}>
                <Text style={{ fontSize: 20 }}>Play</Text>
              </Gradient>
            </Button>
          </CentredWrapper>
        ) : (
          <>
            <Carousel
              ref={carouselRef}
              arrows={false}
              draggable={false}
              swipeable={false}
            >
              {sequences.primary.map((sequence, seqIndex) => (
                <MemoizedSteps
                  key={`seq-${seqIndex}`}
                  isFailed={sequenceFailed}
                  steps={transformSequencesToSteps(sequence)}
                  isActive={seqIndex === currentSequenceIndex}
                  currentStep={currentStep}
                  direction={StepDirection.Horizontal}
                  gameTime={STAGES[stage].gameTime}
                />
              ))}
            </Carousel>
            <ExitWrapper>
              <Button
                onClick={() => {
                  closeGame();
                }}
                borderSize={0}
                size='xs'
                borderColor={'#FFFFFF40'}
              >
                <CloseIcon />
              </Button>
            </ExitWrapper>
            <BubbleArea ref={containerRef}>
              {currentBubble.map((element, index) => {
                const { top, left, size } = randomPositions[index] || {
                  top: -1000,
                  left: -2000,
                  size: 'm',
                };

                return (
                  <MemoizedGameButton
                    isAnimating={clicked[index]}
                    key={`${index}-${element.step}-${element.value}-${element.color}`}
                    style={{
                      position: 'absolute',
                      top: `${top}px`,
                      left: `${left}px`,
                    }}
                    shape='round'
                    size={size}
                    onClick={() => {
                      console.log('click');
                      handleClick(element.step);
                    }}
                    disabled={sequenceCompleted || sequenceFailed}
                  >
                    <MemoizedGradientTimer
                      color={element.color}
                      isAnim={
                        STAGES[stage].help
                          ? currentStep === element.step
                          : false
                      }
                      borderWidth={0}
                      content={
                        <div style={{ fontSize: 20, fontWeight: 600 }}>
                          {element.value}
                        </div>
                      }
                      isBlum={false}
                      static
                      status='active'
                    />
                  </MemoizedGameButton>
                );
              })}
            </BubbleArea>
            <StatsWrapper>
              <Text
                style={{
                  color: '#FFFFFF80',
                  fontSize: 10,
                  lineHeight: '16px',
                  marginBottom: 8,
                }}
              >
                time
                <Text style={{ fontSize: 14 }}>
                  {STAGES[stage].gameTime / 1000}
                </Text>
              </Text>
              <Text
                style={{
                  color: '#FFFFFF80',
                  fontSize: 10,
                  lineHeight: '16px',
                  marginBottom: 8,
                }}
              >
                STAGE
                <Text style={{ fontSize: 14 }}>
                  <Counter value={stage + '/10'} />
                </Text>
              </Text>
              <Text
                style={{
                  color: '#FFFFFF80',
                  fontSize: 10,
                  lineHeight: '16px',
                  marginBottom: 0,
                }}
              >
                factor
                <Text style={{ fontSize: 14 }}>
                  <Counter value={'x' + multiplier} />
                </Text>
              </Text>
              {/* <Text
              style={{
                color: '#FFFFFF80',
                fontSize: 10,
                lineHeight: '16px',
                marginBottom: 0,
              }}
            >
              Correct Sequences
              <Text style={{ fontSize: 14 }}>
                <Counter value={correctSequencesCount} />
              </Text>
            </Text> */}
            </StatsWrapper>
            <InfoWrapper>
              {/* <span
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              {streak > 0 && (
                <StreakAnim value={<Streak>{`${streak}`}</Streak>} />
              )}
            </span> */}
              <Energy />
            </InfoWrapper>
          </>
        )}
      </GameWrapper>
    </>
  );
};
