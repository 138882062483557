import styled, { keyframes } from 'styled-components';
import gifImage from 'shared/icon/gif/bg.gif';
import { colors } from 'app/colors';
import { Text } from 'shared/typography';

// Анимации
const animateBg = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
`;

const animateSpan = keyframes`
  0% {
    transform: rotate(315deg) translateX(0);
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    transform: rotate(315deg) translateX(-1000px);
    opacity: 0;
  }
`;
const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
`;
// Новый компонент с анимацией
export const BounceKeyFrame = styled.div`
  display: inline-block; // Чтобы элемент занимал только свою ширину
  animation: ${bounce} 2s infinite ease-in-out; // Анимация покачивания
`;

// Стили для Background
export const Background = styled.div`
  overflow-x: hidden;
  background: var(--gradient);
  overflow-y: hidden;
  height: 100vh;
  position: relative;
  padding-top: calc(
    var(--tg-safe-area-inset-top) + var(--tg-content-safe-area-inset-top)
  );
  padding-bottom: calc(
    var(--tg-safe-area-inset-bottom) + var(--tg-content-safe-area-inset-bottom)
  );
  padding-left: calc(
    var(--tg-safe-area-inset-left) + var(--tg-content-safe-area-inset-left)
  );
  padding-right: calc(
    var(--tg-safe-area-inset-right) + var(--tg-content-safe-area-inset-right)
  );

  /* background-image: url(${gifImage}); */
`;

// Стили для Layout
export const StyledLayout = styled.div`
  z-index: 1;
  overflow: scroll;
  width: 100%;
  height: 100%;
`;
export const PageWrapper = styled.div`
  height: 100%;
  padding: 15px;
  overflow: hidden;
  padding-bottom: 0;
`;
export const PageContent = styled.div`
  height: calc(100% - 97px);
  max-width: 550px;
  margin: 0 auto;
`;

// Секция
export const Section = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  height: 100%;
  width: 100%;
  background-size: cover;
  animation: ${animateBg} 30s linear infinite;
`;

// Спаны
export const Span = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4px;
  height: 4px;
  background: #fff;
  border-radius: 50%;
  box-shadow:
    0 0 0 4px rgba(255, 255, 255, 0.1),
    0 0 0 8px rgba(255, 255, 255, 0.1),
    0 0 20px rgba(255, 255, 255, 0.1);
  animation: ${animateSpan} 3s linear infinite;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 300px;
    height: 1px;
    background: linear-gradient(90deg, #fff, transparent);
  }

  &:nth-child(1) {
    top: -10px;
    right: -20px;
    left: initial;
    animation-delay: 0s;
    animation-duration: 1s;
  }

  &:nth-child(2) {
    top: -10px;
    right: 80px;
    left: initial;
    animation-delay: 0.2s;
    animation-duration: 3s;
  }

  &:nth-child(3) {
    top: 80;
    right: -20px;
    left: initial;
    animation-delay: 0.4s;
    animation-duration: 2s;
  }

  &:nth-child(4) {
    top: -10px;
    right: 180px;
    left: initial;
    animation-delay: 0.6s;
    animation-duration: 1.5s;
  }

  &:nth-child(5) {
    top: -10px;
    right: 400px;
    left: initial;
    animation-delay: 0.8s;
    animation-duration: 2.5s;
  }

  &:nth-child(6) {
    top: -10px;
    right: 600px;
    left: initial;
    animation-delay: 1s;
    animation-duration: 3s;
  }

  &:nth-child(7) {
    top: 300px;
    right: -20px;
    left: initial;
    animation-delay: 1.2s;
    animation-duration: 1.75s;
  }

  &:nth-child(8) {
    top: -10px;
    right: 700px;
    left: initial;
    animation-delay: 1.4s;
    animation-duration: 1.25s;
  }

  &:nth-child(9) {
    top: -10px;
    right: 1000px;
    left: initial;
    animation-delay: 0.75s;
    animation-duration: 2.25s;
  }

  &:nth-child(10) {
    top: -10px;
    right: 450px;
    left: initial;
    animation-delay: 2.75s;
    animation-duration: 2.75s;
  }
`;

export const Header = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 50px;
  z-index: 2;
  align-items: center;
  justify-content: space-between;
`;

export const H100 = styled.div`
  height: 100%;
  position: relative;
`;

export const CentredWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 20;
`;

export const Scene3dContainer = styled.div``;

export const ButtonWrapper = styled.div`
  margin: 20px 0;
`;

export const BlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const BlockContent = styled.div`
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  letter-spacing: 2px;
  width: 100%;
`;

export const TextContainer = styled(Text)`
  color: var(--secondary-color);
  font-size: 32px;
  font-weight: 600;
`;

export const BonusContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputWrapper = styled.div`
  width: 100%;
  height: 56px;
  position: relative;
  margin: 24px 0;
`;

export const CopyContainer = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
`;

export const BonusContainer = styled.div<{
  background?: string;
  height?: string;
  width?: string;
}>`
  height: ${({ height }) =>
    height || '110px'}; // Используем переданную высоту или дефолтное значение
  width: ${({ width }) =>
    width || '120px'}; // Используем переданную ширину или дефолтное значение
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 4px;
  gap: 6px;
  color: var(--secondary-color);
  background-color: ${({ background }) =>
    background ||
    '#FFFFFF1A'}; // Используем переданный background или дефолтное значение
  padding: 11px;

  clip-path: ${colors.colorTrapezoidShape};
`;
