import { Balance } from 'feature/game/balance';
import { MainGame } from 'feature/game';

import { Header, PageContent, PageWrapper } from 'shared/layout';

import { Menu } from 'widget/menu';

export const Game = () => {
  return (
    <PageWrapper>
      <Header>
        <div />
        <Balance />
        <div />
      </Header>
      <PageContent>
        <MainGame />
      </PageContent>
      <Menu />
    </PageWrapper>
  );
};
