// src/entities/user/api/userApi.ts
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl,
    credentials: 'include',
  }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: '/accounts/login',
        method: 'PATCH',
        body: data,
      }),
    }),
    register: builder.mutation({
      query: (data) => ({
        url: '/accounts',
        method: 'POST',
        body: data,
      }),
    }),
    user: builder.mutation({
      query: (data) => ({
        url: '/accounts',
        method: 'GET',
      }),
    }),
    setWallet: builder.mutation({
      query: (data) => ({
        url: '/accounts/set-wallet',
        method: 'PATCH',
        body: { walletAddress: data },
      }),
    }),
    mint: builder.mutation({
      query: () => ({
        url: '/accounts/mint',
        method: 'PATCH',
      }),
    }),
    leaderboard: builder.mutation({
      query: () => ({
        url: '/accounts/leaderboard',
        method: 'GET',
      }),
    }),
    leaderboardDaily: builder.mutation({
      query: () => ({
        url: '/accounts/leaderboard-daily',
        method: 'GET',
      }),
    }),
    leaderboardReferrals: builder.mutation({
      query: () => ({
        url: '/accounts/leaderboard-referrals',
        method: 'GET',
      }),
    }),
    genRefLink: builder.mutation({
      query: () => ({
        url: '/accounts/generate-referral-link ',
        method: 'GET',
      }),
    }),
    mintModal: builder.mutation({
      query: (data) => ({
        url: '/accounts/modal',
        method: 'PATCH',
        body: { modalName: data },
      }),
    }),
    confirmLink: builder.mutation({
      query: ({ link }) => ({
        url: '/accounts/confirm-link',
        method: 'PATCH',
        body: { link },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useMintMutation,
  useSetWalletMutation,
  useConfirmLinkMutation,
  useLeaderboardMutation,
  useLeaderboardReferralsMutation,
  useLeaderboardDailyMutation,
  useGenRefLinkMutation,
  useMintModalMutation,
} = userApi;
