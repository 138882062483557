// widget/Tasks/Tasks.tsx
import { FC, useEffect } from 'react';
import { useAppSelector } from 'app/store/rootStore';
import { selectTasks, TabType, TasksContent } from 'entities/tasks';
import {
  useFetchDailyTasksMutation,
  useFetchMainTasksMutation,
  useFetchPartnerTasksMutation,
} from 'entities/tasks/api';
import { H100 } from 'shared/layout';

import { Tabs } from 'shared/tabs';
import { Loader } from 'shared/loader';
import { Soon } from 'shared/soon';

export const Tasks: FC = () => {
  const [fetchMainTasks, { isLoading: loading }] = useFetchMainTasksMutation();
  const [fetchDailyTasks, { isLoading: isDailyLoading }] =
    useFetchDailyTasksMutation();
  const [fetchPartnerTasks, { isLoading: isPartnerLoading }] =
    useFetchPartnerTasksMutation();
  const handleTabChange = (activeTab: string) => {};
  const taskState = useAppSelector(selectTasks);

  useEffect(() => {
    fetchMainTasks();
    // fetchDailyTasks();
    // fetchPartnerTasks();
  }, []);

  const isData = taskState.mainTasks.data.length > 0;

  const tabItems = [
    {
      title: 'social',
      segment:
        !loading || isData ? (
          <TasksContent items={taskState.mainTasks.data} />
        ) : (
          <H100>
            <Loader />
          </H100>
        ),
      disable: false,
    },
    {
      title: 'daily',
      segment: (
        <H100>
          <Soon
            title='Coming soon...'
            descr='Daily tasks will be added in the nearest future'
          />
        </H100>
      ),

      disable: false,
    },
    {
      title: 'partners',
      segment: (
        <H100>
          <Soon
            title='Coming soon...'
            descr='Partners tasks will be added in the nearest future'
          />
        </H100>
      ),
      disable: false,
    },
  ];

  return (
    <Tabs
      defaultActiveTab='social'
      items={tabItems}
      onTabChange={handleTabChange}
    />
  );
};
