import { memo, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInterval } from 'react-use';
import { ROUTES } from 'app';

import { Content, LogoWrapper, Wrapper } from './Login.styled';

import { useAuth } from '../model/authProvoider/useAuth';
import { ProgressBar } from 'shared/progress';
import { Logo } from 'shared/logo';
import { waiter } from 'shared/utils';
import { useAppSelector } from 'app/store/rootStore';
import { selectIsMinted, selectIsWaitlisted } from 'entities/user';
import {
  useGenRefLinkMutation,
  useLeaderboardDailyMutation,
  useLeaderboardMutation,
  useLeaderboardReferralsMutation,
} from 'entities/user/model/api';
import { useFetchMainTasksMutation } from 'entities/tasks/api';
import { useToast } from 'shared/toast';
import { useTelegram } from '../model/telegramAuth/TelegramAuth';

// const isDev = true;
// const isDev = false;
const isDev = process.env.NODE_ENV === 'development';

const FAKE_PROGRESS_DURATION_MS = 3000;

// Интервал обновления прогресса (в миллисекундах)
const PROGRESS_INTERVAL_MS = 100;

const MemoizedProgressBar = memo(ProgressBar);

export const Login = () => {
  const { isAuthenticated, auth, isTelegramReady, isError } = useAuth();
  const { startParams } = useTelegram();
  const isMinted = useAppSelector(selectIsMinted);
  const isWaitlisted = useAppSelector(selectIsWaitlisted);
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [progress, setProgress] = useState(0);
  const [genRefLink, { isLoading: loading }] = useGenRefLinkMutation();
  const [getLeaderboard] = useLeaderboardMutation();
  const [getLeaderboardDaily] = useLeaderboardDailyMutation();

  const [getLeaderboardReferrals] = useLeaderboardReferralsMutation();
  const [fetchMainTasks, { isLoading: isTaskLoading }] =
    useFetchMainTasksMutation();

  // Расчет инкремента прогресса для достижения 100% за FAKE_PROGRESS_DURATION_MS
  const progressIncrementSlow = useMemo(
    () => (100 / FAKE_PROGRESS_DURATION_MS) * PROGRESS_INTERVAL_MS * 0.5,
    []
  ); // Мемоизируем, так как значения FAKE_PROGRESS_DURATION_MS и PROGRESS_INTERVAL_MS постоянны

  const progressIncrementFast = useMemo(
    () => (100 / FAKE_PROGRESS_DURATION_MS) * PROGRESS_INTERVAL_MS,
    []
  );

  useInterval(() => {
    setProgress((prev) => {
      if (prev < 30) {
        return Math.min(prev + progressIncrementSlow, 100);
      }
      return Math.min(prev + progressIncrementFast, 100);
    });
  }, PROGRESS_INTERVAL_MS);

  useEffect(() => {
    auth();
  }, [isTelegramReady]);

  useEffect(() => {
    if (isError) {
      navigate(ROUTES.WAITLIST, { replace: true });
      if (startParams) {
        showToast('INVALID LINK!', 'error');
      }
    }
  }, [isError]);

  useEffect(() => {
    // TODO We will need to return back logic with preload 3d model
    if (isAuthenticated || isDev) {
      const executeTasks = async () => {
        try {
          if (isWaitlisted) {
            navigate(ROUTES.WAITLISTSUCCESS, { replace: true });
            return;
          }

          await Promise.all([fetchMainTasks(), genRefLink('')]);

          if (!isMinted) {
            if (startParams) {
              navigate(ROUTES.WELCOME, { replace: true });
              return;
            }

            navigate(ROUTES.MINT, { replace: true });
            return;
          }

          await waiter(2000);

          navigate(ROUTES.LAUNCH, { replace: true });
        } catch (error) {
          console.error('Error executing tasks:', error);
        }
      };

      executeTasks();
    }
  }, [isAuthenticated, isMinted, isWaitlisted]);

  return (
    <Wrapper>
      <Content>
        <LogoWrapper>
          <Logo isLoading />
        </LogoWrapper>
        {/* <progress style={{ width: '100%' }} value={progress / 100} /> */}
        <MemoizedProgressBar current={progress} target={100} />
      </Content>
    </Wrapper>
  );
};
