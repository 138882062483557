import { ROUTES } from 'app';
import { Timer } from 'shared/timer';
import { colors } from 'app/colors';
import { Button } from 'shared/button';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Gradient } from 'shared/gradient';
import { use3dModelContext } from 'feature/3d';
import { differenceInSeconds } from 'date-fns';
import { StyledText } from './LaunchDate.styled';
import { selectUser, userSlice } from '../../entities/user';
import { CentredWrapper, Scene3dContainer } from 'shared/layout';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Text } from 'shared/typography';
import { useModal } from 'shared/modal';
import { SintReadyModal } from './sintReadyModal';
import { useMintModalMutation } from 'entities/user/model/api';
import { useAppDispatch } from 'app/store/rootStore';
import { CharacterStats } from 'widget/characterStats';

export const LaunchDate = () => {
  const {
    mintDate,
    accountReferralInfo,
    mintModal: isMintModalShown,
  } = useSelector(selectUser);
  const { mount3dScene, unmount3dScene, movementControllerOfModel } =
    use3dModelContext();
  const sceneContainerLaunchPage = useRef<HTMLDivElement | null>(null);
  const [isTimeUp, setIsTimeUp] = useState(false);
  const { setIsOpen, setModalProps, isOpen } = useModal();
  const [mintModal] = useMintModalMutation();
  const dispatch = useAppDispatch();
  const modalShownRef = useRef(false);

  const targetDate = useMemo(() => {
    const durationMintMinutes = process.env.REACT_APP_MINT_DURATION || 60000;

    // TODO REMOVE * 1000 when it will be fixed on server side.
    return new Date(+new Date(mintDate * 1000) + +durationMintMinutes);
  }, [mintDate]);

  const hasAvailableInvites = useMemo(() => {
    if (!accountReferralInfo) return false;
    return (
      accountReferralInfo.maxNumberOfAccounts -
        accountReferralInfo.invitedCount >
      0
    );
  }, [accountReferralInfo]);

  useEffect(() => {
    const checkTimeAndShowModal = () => {
      if (isMintModalShown || modalShownRef.current) return;

      const timeUp = differenceInSeconds(targetDate, new Date()) <= 0;
      setIsTimeUp(timeUp);

      if (timeUp) {
        modalShownRef.current = true;
        setModalProps({
          children: <SintReadyModal onClose={() => setIsOpen(false)} />,
          onRequestClose: () => setIsOpen(false),
          isOpen: true,
        });
        setIsOpen(true);

        if (!isOpen) {
          mintModal('mintModal')
            .unwrap()
            .then(() => {
              dispatch(userSlice.actions.updateMintModal(true));
            })
            .catch((error) => {
              console.error('Failed to update modal status:', error);
            });
        }
      }
    };

    const intervalId = setInterval(checkTimeAndShowModal, 1000);
    return () => clearInterval(intervalId);
  }, [targetDate, isMintModalShown, setIsOpen, setModalProps]);

  useEffect(() => {
    if (sceneContainerLaunchPage && sceneContainerLaunchPage.current) {
      mount3dScene(sceneContainerLaunchPage.current.id);
    }
  }, [sceneContainerLaunchPage]);

  useEffect(() => {
    if (
      movementControllerOfModel &&
      sceneContainerLaunchPage &&
      sceneContainerLaunchPage.current
    ) {
      // here we can not pass the targetDate, the function will calculate it itself if it is missing.
      if (isTimeUp) {
        movementControllerOfModel.moveSintLaunchPage();
      } else {
        movementControllerOfModel.moveHologramWithOpacityToLaunchPage(
          new Date(mintDate),
          targetDate
        );
      }
    }
  }, [movementControllerOfModel, isTimeUp]);

  useLayoutEffect(() => {
    return () => {
      console.log('SCENE-UNMOUNT');
      unmount3dScene();
    };
  }, []);

  return (
    <CentredWrapper>
      <CharacterStats />
      <Scene3dContainer
        id={'3d-scene-launch-page'}
        ref={sceneContainerLaunchPage}
        style={{
          width: '100%',
          height: '350px',
        }}
      />

      <StyledText>
        {isTimeUp ? (
          <>
            Train Your SYNTHETIC INTELLIGENCE,
            <br />
            Own the Future
          </>
        ) : (
          'Minting in progress'
        )}
      </StyledText>
      {!isTimeUp && <Timer targetDate={targetDate} onTimeUp={setIsTimeUp} />}
      <NavLink
        style={{ marginTop: 10 }}
        to={hasAvailableInvites ? ROUTES.REFERRAL : ROUTES.TASKS}
      >
        <Button decoration='basic' borderColor={colors.primaryColor} size='l'>
          <Gradient color={colors.primaryColor}>
            <Text>{hasAvailableInvites ? 'INVITE' : 'VIEW TASKS'}</Text>
          </Gradient>
        </Button>
      </NavLink>
    </CentredWrapper>
  );
};
