import { getRandomInt } from 'shared/utils';
import { MAX_UNIQUE_COMBINATIONS, PARAMETERS } from './config';
import { Step } from 'shared/steps';

export interface GeneratedElement {
  value: number;
  color: string;
  expiredTime?: number;
  step: number;
}

export interface Sequence {
  primary: GeneratedElement[][];
  fake: GeneratedElement[][];
  final: GeneratedElement[][];
}

interface Stage {
  minPrimary: number;
  maxPrimary: number;
  minFake: number;
  maxFake: number;
  bubbleSize: string[];
  gameTime: number;
  reward: number;
  help: boolean;
}

export const BUTTON_SIZE = 70;
export const TOTAL_SEQUENCES = 1;

export const SCENE_SIZE = {
  width: 330,
  height: 400,
};

export const TIMER_START = 20000;
export const TIME_DECREMENT = 100;

export const STAGES: Record<number, Stage> = {
  1: {
    minPrimary: 2,
    maxPrimary: 2,
    minFake: 0,
    maxFake: 0,
    bubbleSize: ['l'],
    gameTime: 20000, // 20 секунд
    reward: 1,
    help: true,
  },
  2: {
    minPrimary: 3,
    maxPrimary: 3,
    minFake: 0,
    maxFake: 0,
    bubbleSize: ['l'],
    gameTime: 15000, // 15 секунд
    reward: 1,
    help: true,
  },
  3: {
    minPrimary: 4,
    maxPrimary: 4,
    minFake: 0,
    maxFake: 0,
    bubbleSize: ['l'],
    gameTime: 15000, // 15 секунд
    reward: 2,
    help: false,
  },
  4: {
    minPrimary: 5,
    maxPrimary: 5,
    minFake: 0,
    maxFake: 0,
    bubbleSize: ['l'],
    gameTime: 10000, // 10 секунд
    reward: 2,
    help: false,
  },
  5: {
    minPrimary: 6,
    maxPrimary: 6,
    minFake: 0,
    maxFake: 0,
    bubbleSize: ['m', 'l'],
    gameTime: 10000, // 10 секунд
    reward: 3,
    help: false,
  },
  6: {
    minPrimary: 7,
    maxPrimary: 7,
    minFake: 0,
    maxFake: 0,
    bubbleSize: ['m', 'l'],
    gameTime: 10000, // 10 секунд
    reward: 3,
    help: false,
  },
  7: {
    minPrimary: 2,
    maxPrimary: 7,
    minFake: 0,
    maxFake: 0,
    bubbleSize: ['m', 'l'],
    gameTime: 10000, // 10 секунд
    reward: 3,
    help: false,
  },
  8: {
    minPrimary: 2,
    maxPrimary: 7,
    minFake: 0,
    maxFake: 3,
    bubbleSize: ['m', 'l'],
    gameTime: 10000, // 10 секунд
    reward: 3,
    help: false,
  },
  9: {
    minPrimary: 2,
    maxPrimary: 7,
    minFake: 0,
    maxFake: 3,
    bubbleSize: ['m', 'l'],
    gameTime: 8000, // 8 секунд
    reward: 3,
    help: false,
  },
  10: {
    minPrimary: 2,
    maxPrimary: 7,
    minFake: 0,
    maxFake: 3,
    bubbleSize: ['m', 'l'],
    gameTime: 7000, // 7 секунд
    reward: 3,
    help: false,
  },
};

export const getRandomSize = (): 's' | 'm' | 'l' => {
  const randomValue = Math.random();
  if (randomValue < 0.33) {
    return 's';
  } else if (randomValue < 0.66) {
    return 'm';
  } else {
    return 'l';
  }
};

const generateUniqueArray = (
  length: number,
  usedCombinations: Set<string>,
  fake: boolean = false // Добавляем параметр для фейковых элементов
): GeneratedElement[] => {
  const array: GeneratedElement[] = [];

  while (array.length < length) {
    const color =
      PARAMETERS.COLORS[getRandomInt(0, PARAMETERS.COLORS.length - 1)];
    const value =
      PARAMETERS.VALUES[getRandomInt(0, PARAMETERS.VALUES.length - 1)];
    const combination = `${color}-${value}`;

    if (!usedCombinations.has(combination)) {
      usedCombinations.add(combination);
      array.push({
        value,
        color,
        expiredTime: Date.now() + 60000, // Срок действия 60 секунд
        step: fake ? -1 : array.length, // Если fake, то step = -1, иначе текущий индекс
      });
    }
  }

  return array;
};

export const transformSequencesToSteps = (
  sequences: GeneratedElement[]
): Step[] => {
  return sequences.map((element) => ({
    label: element.value.toString(),
    value: element.step.toString(),
    color: element.color,
  }));
};

// Функция для объединения и перемешивания массивов
export const shuffleAndCombineArrays = (
  primaryArray: GeneratedElement[],
  fakeElements: GeneratedElement[]
): GeneratedElement[] => {
  return [...primaryArray, ...fakeElements].sort(() => Math.random() - 0.5);
};

// Основная функция generateData
export const generateData = (
  minPrimary: number,
  maxPrimary: number,
  minFake: number,
  maxFake: number
): {
  primaryArray: GeneratedElement[];
  fakeElements: GeneratedElement[];
  finalArray: GeneratedElement[];
} => {
  const primaryLength = getRandomInt(minPrimary, maxPrimary); // Длина основного массива
  const fakeCount = getRandomInt(minFake, maxFake); // Количество фейковых элементов
  const totalCount = primaryLength + fakeCount;

  if (totalCount > MAX_UNIQUE_COMBINATIONS) {
    throw new Error(
      'Impossible to generate a unique array with the given parameters'
    );
  }

  const usedCombinations = new Set<string>();
  const primaryArray = generateUniqueArray(primaryLength, usedCombinations);
  const fakeElements = generateUniqueArray(fakeCount, usedCombinations, true); // Передаем true для фейковых элементов

  // Создание finalArray через новую функцию
  const finalArray = primaryArray.concat(fakeElements);

  // Возвращаем finalArray и primaryArray
  return { primaryArray, fakeElements, finalArray };
};
