export enum ROUTES {
  APP = '/app',
  MINT = '/app/mint',
  GAME = '/app/game',
  TASKS = '/app/tasks',
  BOOSTS = '/app/boosts',
  LAUNCH = '/app/launch',
  RATINGS = '/app/ratings',
  PROFILE = '/app/profile',
  SANDBOX = '/app/sandbox',
  WELCOME = '/app/welcome',
  LANDING = '/app/landing',
  REFERRAL = '/app/referral',
  WALLET = '/app/connectwallet',
  WAITLISTSUCCESS = '/app/waitlistsuccess',

  LOGIN = '/',
  VIEWPORT = '/qr',
  WAITLIST = '/waitlist',
}
