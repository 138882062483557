import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { AnimatedBorderContainer } from './AnimatedBorder.styled';

interface AnimatedBorderProps {
  gameTime: number;
  isClockwise?: boolean;
  children: React.ReactNode;
}

const AnimatedBorder: React.FC<AnimatedBorderProps> = ({
  gameTime,
  isClockwise,
  children,
}) => {
  const pathRef = useRef<SVGPathElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const startTimeRef = useRef<number | null>(null);
  const animationFrameIdRef = useRef<number>();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [dotPosition, setDotPosition] = useState({ x: 1, y: 20 });
  const strokeWidth = 1.5;
  const radius = 20;

  const gradientDefs = useMemo(
    () => (
      <defs>
        <linearGradient id='borderGradient' x1='0%' y1='0%' x2='100%' y2='0%'>
          <stop offset='0%' style={{ stopColor: '#BEAFFD', stopOpacity: 1 }} />
          <stop
            offset='100%'
            style={{ stopColor: '#7058D5', stopOpacity: 1 }}
          />
        </linearGradient>
        <radialGradient id='dotGradient'>
          <stop offset='5%' stopColor='white' stopOpacity='1' />
          <stop offset='15%' stopColor='white' stopOpacity='0.5' />
          <stop offset='100%' stopColor='white' stopOpacity='0' />
        </radialGradient>
      </defs>
    ),
    []
  );

  const dotElement = useMemo(
    () => (
      <circle
        cx={dotPosition.x}
        cy={dotPosition.y}
        r='15'
        fill='url(#dotGradient)'
      />
    ),
    [dotPosition.x, dotPosition.y]
  );

  const pathConfig = useMemo(() => {
    const width = dimensions.width - strokeWidth;
    const height = dimensions.height - strokeWidth;
    const straightWidth = width - 2 * radius;
    const straightHeight = height - 2 * radius;
    const cornerLength = radius * (Math.PI / 2);
    const perimeter =
      2 * Math.PI * radius +
      2 * (width - 2 * radius) +
      2 * (height - 2 * radius);

    const pathD = `M ${radius + (dimensions.width - 2 * radius) / 2 + strokeWidth / 2},${strokeWidth / 2}
       L ${radius + strokeWidth / 2},${strokeWidth / 2}
       A ${radius},${radius} 0 0 0 ${strokeWidth / 2},${radius + strokeWidth / 2}
       L ${strokeWidth / 2},${dimensions.height - radius + strokeWidth / 2}
       A ${radius},${radius} 0 0 0 ${radius + strokeWidth / 2},${dimensions.height + strokeWidth / 2}
       L ${dimensions.width - radius + strokeWidth / 2},${dimensions.height + strokeWidth / 2}
       A ${radius},${radius} 0 0 0 ${dimensions.width + strokeWidth / 2},${dimensions.height - radius + strokeWidth / 2}
       L ${dimensions.width + strokeWidth / 2},${radius + strokeWidth / 2}
       A ${radius},${radius} 0 0 0 ${dimensions.width - radius + strokeWidth / 2},${strokeWidth / 2}
       L ${radius + (dimensions.width - 2 * radius) / 2 + strokeWidth / 2},${strokeWidth / 2}
       Z`;

    return {
      width,
      height,
      straightWidth,
      straightHeight,
      cornerLength,
      perimeter,
      pathD,
    };
  }, [dimensions]);

  const updateDimensions = useCallback(() => {
    if (containerRef.current) {
      const { width, height } = containerRef.current.getBoundingClientRect();
      setDimensions({ width, height });
    }
  }, []);

  useEffect(() => {
    updateDimensions();
    const resizeObserver = new ResizeObserver(updateDimensions);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [updateDimensions]);

  const animate = useCallback(
    (timestamp: number) => {
      const path = pathRef.current;
      if (!path) return;

      if (!startTimeRef.current) {
        startTimeRef.current = timestamp;
      }

      const elapsed = timestamp - startTimeRef.current;
      const progress = Math.min(elapsed / gameTime, 1);
      const distance = pathConfig.perimeter * progress;

      path.style.strokeDasharray = `${pathConfig.perimeter}`;
      path.style.strokeDashoffset = `${!isClockwise ? -distance : distance}`;

      const point = path.getPointAtLength(
        !isClockwise ? distance : pathConfig.perimeter - distance
      );
      setDotPosition({
        x: point.x,
        y: point.y,
      });

      if (progress < 1) {
        animationFrameIdRef.current = requestAnimationFrame(animate);
      }
    },
    [gameTime, pathConfig, isClockwise]
  );

  useEffect(() => {
    const path = pathRef.current;
    if (!path) return;

    // Reset animation state
    startTimeRef.current = null;
    if (animationFrameIdRef.current) {
      cancelAnimationFrame(animationFrameIdRef.current);
    }

    // Reset SVG properties
    path.style.strokeDasharray = `${pathConfig.perimeter}`;
    path.style.strokeDashoffset = '0';

    // Reset dot to starting position
    setDotPosition({
      x: radius + pathConfig.straightWidth / 2 + strokeWidth / 2,
      y: strokeWidth / 2,
    });

    // Start new animation
    animationFrameIdRef.current = requestAnimationFrame(animate);

    return () => {
      if (animationFrameIdRef.current) {
        cancelAnimationFrame(animationFrameIdRef.current);
      }
    };
  }, [gameTime, pathConfig, animate]); // gameTime in dependencies ensures animation restarts when timer changes

  // Memoize the path element
  const pathElement = useMemo(
    () => (
      <path
        ref={pathRef}
        d={pathConfig.pathD}
        fill='none'
        stroke='url(#borderGradient)'
        strokeWidth={strokeWidth}
      />
    ),
    [pathConfig.pathD]
  );

  return (
    <AnimatedBorderContainer ref={containerRef}>
      <svg width='100%' height='100%' preserveAspectRatio='none'>
        {gradientDefs}
        {pathElement}
        {dotElement}
      </svg>
      <div className='children-container'>{children}</div>
    </AnimatedBorderContainer>
  );
};

export default React.memo(AnimatedBorder);
