// src/entities/tasks/ui/Tasks.tsx
import { FC, useState, useMemo } from 'react';
import { TaskList } from '../Tasks.styled';
import { TaskItem } from './TaskItem';
import { ItemsListProps } from '../tasks.types';

export const TasksContent: FC<ItemsListProps> = ({ items }) => {
  const [isCheckedTasks, setIsCheckedTasks] = useState<Set<number>>(new Set());

  const memoizedTasks = useMemo(() => items, [items]);

  const handleCheckTask = (taskId: number) => {
    setIsCheckedTasks((prev) => new Set(prev).add(taskId));
  };

  return (
    <TaskList>
      {memoizedTasks.map((task) => (
        <TaskItem
          key={task.id}
          task={task}
          isChecked={isCheckedTasks.has(+task.id)}
          onCheck={handleCheckTask}
        />
      ))}
    </TaskList>
  );
};
