import { Counter } from 'shared/counter';
import styled from 'styled-components';

export const Wrapper = styled.div`
  text-transform: uppercase;
`;
export const Title = styled.div`
  color: var(--white-color);
  opacity: 0.5;
  font-family: Titillium Web;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0.12px;
  text-align: center;
`;
export const BalanceRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  & > span {
    font-size: 24px;
    font-weight: 400;
    padding-bottom: 4px;
    color: var(--white-color);
  }
`;

export const Content = styled.div`
  height: 35px;
  display: flex;
  min-width: 83px;
`;

export const CircleBg = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  width: 24px;
  background: var(--white-color);
  border-radius: 50%;
  position: relative;
`;
