import {
  createBrowserRouter,
  Navigate,
  Outlet,
  useLocation,
} from 'react-router-dom';

import { BrowserView, MobileOnlyView, TabletView } from 'react-device-detect';

import { ROUTES } from './routes';

import { ProtectedRoute } from 'feature/auth';

import { Background, Layout } from 'shared/layout';

import { LoginPage } from 'pages/Login';
import { TaskPage } from 'pages/Tasks';
import { Mint } from 'pages/Mint';
import { Launch } from 'pages/Launch';
import { SandBox } from 'pages/SandBox';
import { Game } from 'pages/Game';
import { Profile } from 'pages/Profile';
import { Referral } from 'pages/Referral';
import { LeaderBoard } from 'pages/LeaderBoard';
import { Landing } from 'landing/Landing';
import { ConnectWallet } from 'pages/ConnectWallet';
import { WaitList } from 'pages/WaitList';
import { WaitListSuccess } from 'pages/WaitListSuccess';

import { AnimatePresence, motion } from 'framer-motion';
import { QRScreen } from 'pages/QRScreen';
import { WelcomePage } from '../../pages/Welcome';

const routeVariants = {
  initial: { opacity: 0.95 },
  animate: {
    opacity: 1,

    transition: {
      opacity: { duration: 0.6 },
    },
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.3 },
  },
};

const AnimatedPageWrapper = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();

  return (
    <motion.div
      key={location.pathname} // Это гарантирует, что при смене маршрута будет новый ключ, и анимация будет срабатывать.
      variants={routeVariants}
      initial='initial'
      animate='animate'
      exit='exit'
      style={{ width: '100%' }}
    >
      {children}
    </motion.div>
  );
};

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <AnimatePresence>
        <AnimatedPageWrapper>
          <Outlet />
        </AnimatedPageWrapper>
      </AnimatePresence>
    ),
    children: [
      {
        path: ROUTES.LOGIN,
        element: (
          <>
            <Layout>
              <LoginPage />
            </Layout>
          </>
        ),
      },
      {
        path: ROUTES.VIEWPORT,
        element: (
          <Layout>
            <QRScreen />
          </Layout>
        ),
      },
      {
        path: ROUTES.WAITLIST,
        element: (
          <Background>
            <WaitList />
          </Background>
        ),
      },
      {
        path: ROUTES.WAITLISTSUCCESS,
        element: (
          <Background>
            <WaitListSuccess />
          </Background>
        ),
      },
      {
        path: ROUTES.LANDING,
        element: <Landing />,
      },
      {
        path: ROUTES.APP,
        element: (
          <ProtectedRoute>
            <Layout>
              <Outlet />
            </Layout>
          </ProtectedRoute>
        ),
        children: [
          {
            path: ROUTES.WELCOME,
            element: <WelcomePage />,
          },
          {
            path: ROUTES.MINT,
            element: <Mint />,
          },
          {
            path: ROUTES.LAUNCH,
            element: <Launch />,
          },
          {
            path: ROUTES.GAME,
            element: <Game />,
          },
          {
            path: ROUTES.TASKS,
            element: <TaskPage />,
          },
          {
            path: ROUTES.RATINGS,
            element: <LeaderBoard />,
          },
          {
            path: ROUTES.SANDBOX,
            element: <SandBox />,
          },
          {
            path: ROUTES.PROFILE,
            element: <Profile />,
          },
          {
            path: ROUTES.REFERRAL,
            element: <Referral />,
          },
          {
            path: ROUTES.WALLET,
            element: <ConnectWallet />,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to={ROUTES.LOGIN} />, // Перенаправляем на логин для несуществующих маршрутов
  },
]);
